import {Bc} from "./Bc";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import {AspectRatio, CardActions, CardOverflow} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";

import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {useAuth} from "../hooks/AuthenticationContext";
import Avatar from "@mui/joy/Avatar";
import * as React from "react";

function Profile() {
    const user = useAuth();
    return <>
        <Bc title="My Profile">

        </Bc>
        <Typography level="h2">My Profile</Typography>
        <Stack
            spacing={4}
            sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: {xs: 2, md: 6},
                py: {xs: 2, md: 3},
                width: "600px",
            }}
        >
            <Card>
                <Box sx={{mb: 1}}>
                    <Typography level="title-md">Personal info</Typography>
                </Box>
                <Divider/>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{display: {xs: 'none', md: 'flex'}, my: 1}}
                >
                    <Stack direction="column" spacing={1}>
                        <AspectRatio
                            ratio="1"
                            maxHeight={200}
                            sx={{flex: 1, minWidth: 120, borderRadius: '100%'}}
                        >
                            <Avatar
                                variant="outlined"
                                size="sm"
                                src={user.photoURL}
                            />
                        </AspectRatio>
                        {/*<IconButton
                        aria-label="upload new picture"
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        sx={{
                            bgcolor: 'background.body',
                            position: 'absolute',
                            zIndex: 2,
                            borderRadius: '50%',
                            left: 100,
                            top: 170,
                            boxShadow: 'sm',
                        }}
                    >
                        <EditRoundedIcon/>
                    </IconButton>*/}
                    </Stack>
                    <Stack spacing={2} sx={{flexGrow: 1}}>
                        <Stack spacing={1}>
                            <FormLabel>Display Name</FormLabel>
                            <FormControl
                                sx={{display: {sm: 'flex-column', md: 'flex-row'}, gap: 2}}
                            >
                                <Input size="sm" placeholder="Display Name" value={user.displayName} disabled/>
                            </FormControl>
                        </Stack>


                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input
                                size="sm"
                                type="email"
                                startDecorator={<EmailRoundedIcon/>}
                                placeholder="email"
                                value={user.email}
                                disabled

                            />
                        </FormControl>
                    </Stack>
                </Stack>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{display: {xs: 'flex', md: 'none'}, my: 1}}
                >
                    <Stack direction="row" spacing={2}>
                        <Stack direction="column" spacing={1}>
                            <AspectRatio
                                ratio="1"
                                maxHeight={108}
                                sx={{flex: 1, minWidth: 108, borderRadius: '100%'}}
                            >
                                <img
                                    src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                                    srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                                    loading="lazy"
                                    alt=""
                                />
                            </AspectRatio>
                            <IconButton
                                aria-label="upload new picture"
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    bgcolor: 'background.body',
                                    position: 'absolute',
                                    zIndex: 2,
                                    borderRadius: '50%',
                                    left: 85,
                                    top: 180,
                                    boxShadow: 'sm',
                                }}
                            >
                                <EditRoundedIcon/>
                            </IconButton>
                        </Stack>
                        <Stack spacing={1} sx={{flexGrow: 1}}>
                            <FormLabel>Name</FormLabel>
                            <FormControl
                                sx={{
                                    display: {
                                        sm: 'flex-column',
                                        md: 'flex-row',
                                    },
                                    gap: 2,
                                }}
                            >
                                <Input size="sm" placeholder="First name"/>
                                <Input size="sm" placeholder="Last name"/>
                            </FormControl>
                        </Stack>
                    </Stack>
                    <FormControl>
                        <FormLabel>Role</FormLabel>
                        <Input size="sm" defaultValue="UI Developer"/>
                    </FormControl>
                    <FormControl sx={{flexGrow: 1}}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            size="sm"
                            type="email"
                            startDecorator={<EmailRoundedIcon/>}
                            placeholder="email"
                            defaultValue="siriwatk@test.com"
                            sx={{flexGrow: 1}}
                        />
                    </FormControl>

                </Stack>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                        <Button size="sm" component="a" href="/profile/remove" variant="solid" color="danger">Request Account Remove</Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </Stack>
    </>
}

export {Profile};