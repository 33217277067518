import {Bc, BcLink} from "./Bc";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import * as React from "react";
import Button from "@mui/joy/Button";
import {useRef, useState} from "react";
import {useAuth} from "../hooks/AuthenticationContext";
import {TextField} from "@mui/joy";

function RemoveAccount() {
    const [isValid, setIsValid] = useState(true);
    const [inputData, setInputData] = useState("");
    const user = useAuth();

    const onChange = () => event => {
        setIsValid(event.target.value === "yes");
        setInputData(event.target.value);
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (inputData.toLowerCase() !== "yes") {
            setIsValid(false);
            return;
        }
        const token = await user.getIdToken(true);
        const res = await fetch("/api/account/remove", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        })
        //alert("s")
        setIsValid(false);
    }


    return <>
        <Bc title="Remove account">
            <BcLink href="/profile">Profile</BcLink>
        </Bc>
        <Typography level="h2">Remove account</Typography>
        <Typography level="body-sm">
            Full account data removing can take up to 24 hours.
        </Typography>

        <Stack direction="column" component="form" sx={{maxWidth: "150px"}} onSubmit={onSubmit}>
            <Stack spacing={1}>

                <FormControl
                    sx={{display: {sm: 'flex-column', md: 'flex-row'}, gap: 2}}
                >
                    <Stack direction="row" spacing={1}>
                        <Input name="confirmation" placeholder="Type 'yes'" error={!isValid}
                               onChange={onChange()}
                               size="sm" sx={{minWidth: "150px"}}
                               color={(isValid === false ? "danger" : "neutral")}/>
                        <Button type="submit" color="danger" variant="solid">Remove</Button>
                    </Stack>
                </FormControl>
            </Stack>
        </Stack>
    </>
}

export {RemoveAccount};