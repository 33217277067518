import firebase from 'firebase/compat/app';
import {browserSessionPersistence, getAuth, setPersistence, connectAuthEmulator} from "firebase/auth";

const config = {
    apiKey: 'AIzaSyCk7ymF1LdqWzdYqA4-dSuJqiwR3z1xkGg',
    authDomain: 'logsizer.app',
    projectId: 'fosize',
};

const firebaseApp = firebase.initializeApp(config);
const auth = getAuth(firebaseApp);
//connectAuthEmulator(auth, "http://127.0.0.1:9099");

await setPersistence(auth, browserSessionPersistence);


export {firebaseApp, auth};