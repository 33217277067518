import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes} from "react-router-dom";
import CssBaseline from "@mui/joy/CssBaseline";
import {CssVarsProvider} from "@mui/joy";
import JoySignInSideTemplate from "./components/Login";
import {AuthProvider} from "./hooks/AuthenticationContext";
import {Profile} from "./components/Profile";
import {RemoveAccount} from "./components/RemoveAccount";

const root = ReactDOM.createRoot(document.getElementById('root'));

const routerProvider = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" Component={App}>
                    <Route path="profile" Component={Profile}/>
                    <Route path="profile/remove" Component={RemoveAccount}/>
                </Route>
                <Route path="login" element={<JoySignInSideTemplate/>}/>
            </>
        )
    )
;

root.render(
    <React.StrictMode>
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <AuthProvider>
                <RouterProvider router={routerProvider}/>
            </AuthProvider>
        </CssVarsProvider>
    </React.StrictMode>
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
