import {Navigate, Route, useLocation} from "react-router-dom";
import {useAuth} from "../hooks/AuthenticationContext";

export const ProtectedRoute = ({ children, path } ) => {
    const user = useAuth();
    const location = useLocation();
    if (!user) {
            return <Navigate to={`/login?r=${encodeURI(location.pathname)}`} replace />;
    }
    return children;
};