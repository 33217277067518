import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Link from "@mui/joy/Link";

function Bc({children, title}) {
    return <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon fontSize="sm"/>}
            sx={{pl: 0}}
        >
            <Link
                underline="none"
                color="neutral"
                href="/"
                aria-label="Home"
            >
                <HomeRoundedIcon/>
            </Link>
            {children}
            <Typography color="primary" fontWeight={500} fontSize={12}>
                {title}
            </Typography>
        </Breadcrumbs>
    </Box>
}

function BcLink({children, href}) {
    return <Link
        underline="hover"
        color="neutral"
        href={href}
        fontSize={12}
        fontWeight={500}
    >
        {children}
    </Link>
}


export {Bc, BcLink};