import * as React from 'react';
import {useColorScheme} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, {formLabelClasses} from '@mui/joy/FormLabel';
import IconButton, {IconButtonProps} from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import GoogleIcon from './GoogleIcon';
import SvgIcon from "@mui/material/SvgIcon";
import {ReactComponent as FosizeLogo} from '../logo.svg';
import {useState} from "react";
import {CircularProgress} from "@mui/joy";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useAuthContext, signInWithEmail, signInWithGoogle, useAuth} from "../hooks/AuthenticationContext";

function ColorSchemeToggle({onClick, ...props}) {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = useState(false);


    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="neutral" disabled/>;
    }
    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="neutral"
            aria-label="toggle light/dark mode"
            {...props}
            onClick={(event) => {
                if (mode === 'light') {
                    setMode('dark');
                } else {
                    setMode('light');
                }
                onClick?.(event);
            }}
        >
            {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
        </IconButton>
    );
}


export default function JoySignInSideTemplate() {
    const [hasLoginError, setHasLoginError] = useState(false)
    const [loginInProgress, setLoginInProgress] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();
    const {setUser} = useAuthContext();


    async function login(loginFunc) {
        setLoginInProgress(true)
        try {
            const u = await loginFunc();
            setUser(u);
            const sp = new URLSearchParams(location.search);
            for (const [key, value] of sp) {
                if (key === "r") {
                    navigate(decodeURI(value));
                    break;
                }
            }

        } catch (e) {
            setHasLoginError(true)
        } finally {
            setLoginInProgress(true)
        }
    }

    async function onLoginFormSubmin(event) {
        event.preventDefault();
        return login(() => {
            const formElements = event.currentTarget.elements;
            return signInWithEmail(formElements.email.value, formElements.password.value, formElements.persistent.checked);
        })


        /*setLoginInProgress(true)
        const formElements = event.currentTarget.elements;
        try {
            const u = await signInWithEmail(formElements.email.value, formElements.password.value, formElements.persistent.checked);
            setUser(u);
            const sp = new URLSearchParams(location.search);
            for (const [key, value] of sp) {
                if (key === "r") {
                    navigate(decodeURI(value));
                    break;
                }
            }
        } catch (e) {
            setHasLoginError(true)
        }
        setLoginInProgress(false)*/
    }

    async function googleLoginClick() {
        return login(() => {
            return signInWithGoogle();
        })
    }

    const user = useAuth();
    if (user) {
        return <Navigate to="/"/>
    }
    return (
        <>

            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                gap: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton variant="soft" color="primary" size="sm">
                                <SvgIcon component={FosizeLogo} viewBox="0 0 13 13"/>
                            </IconButton>
                            <Typography level="title-lg">Logsizer</Typography>
                        </Box>
                        <ColorSchemeToggle/>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{mb: 2}}>
                            <Stack gap={1}>
                                <Typography level="h3">Sign in</Typography>
                                {/*<Typography level="body-sm">
                                    New to company?{' '}
                                    <Link href="#replace-with-a-link" level="title-sm">
                                        Sign up!
                                    </Link>
                                </Typography>*/}
                            </Stack>

                            <Button
                                variant="soft"
                                color="neutral"
                                fullWidth
                                startDecorator={<GoogleIcon/>}
                                onClick={() => googleLoginClick()}
                            >
                                Continue with Google
                            </Button>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: {xs: '#FFF', md: 'text.tertiary'},
                                    '--Divider-lineColor': {
                                        xs: '#FFF',
                                        md: 'var(--joy-palette-divider)',
                                    },
                                },
                            })}
                        >
                            or
                        </Divider>


                        <Stack gap={4} sx={{mt: 2}}>
                            <Typography level="h3">Sign in</Typography>
                            {loginInProgress ? <Box sx={{textAlign: 'center'}}>
                                    <CircularProgress/>
                                </Box> :
                                <>
                                    {hasLoginError && <Typography level="body1" sx={
                                        theme => ({color: `${theme.vars.palette.danger.plainColor}`})
                                    }>
                                        Invalid email or password
                                    </Typography>}
                                    <form
                                        onSubmit={event => onLoginFormSubmin(event)}
                                    >
                                        <FormControl required>
                                            <FormLabel>Email</FormLabel>
                                            <Input type="email" name="email"/>
                                        </FormControl>
                                        <FormControl required>
                                            <FormLabel>Password</FormLabel>
                                            <Input type="password" name="password"/>
                                        </FormControl>
                                        <Stack gap={4} sx={{mt: 2}}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Checkbox size="sm" label="Remember me" name="persistent"/>
                                                <Link level="title-sm" href="#replace-with-a-link">
                                                    Forgot your password?
                                                </Link>
                                            </Box>
                                            <Button type="submit" fullWidth>
                                                Sign in
                                            </Button>
                                        </Stack>
                                    </form>
                                </>
                            }


                        </Stack>
                    </Box>
                    <Box component="footer" sx={{py: 3}}>
                        <Typography level="body-xs" textAlign="center">
                            © Fosize PSA {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            />
        </>
    );
}
