import * as React from 'react';
import Box from '@mui/joy/Box';

import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
// icons
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import feather from 'feather-icons';
import OrderTable from "./components/OrderTable";
import OrderList from "./components/OrderList";
import {Outlet, Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "./components/ProtectedRoute";
import {RemoveAccount} from "./components/RemoveAccount";
import {Profile} from "./components/Profile";

const useEnhancedEffect =
    window ? React.useLayoutEffect : React.useEffect;

function App({children}) {
    useEnhancedEffect(() => {
        feather.replace();
    });
    return (
        <ProtectedRoute>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <Sidebar/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {
                            xs: 2,
                            md: 6,
                        },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                        },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    {/*<Routes>
                        <Route path="profile" Component={Profile}>
                            <Route path="remove" Component={RemoveAccount}/>
                        </Route>
                    </Routes>*/}
                    <Outlet/>


                    {/*<Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Orders
                            </Typography>
                        </Breadcrumbs>
                    </Box>*/}
                    {/*<Box
                        sx={{
                            display: 'flex',
                            my: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'start', sm: 'center'},
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography level="h2">Orders</Typography>
                        <Button
                            color="primary"
                            startDecorator={<DownloadRoundedIcon/>}
                            size="sm"
                        >
                            Download PDF
                        </Button>
                    </Box>*/}
                    {/*<OrderTable/>*/}
                    {/*<OrderList/>*/}
                </Box>
            </Box>
        </ProtectedRoute>
    )
        ;
}

export default App;
