import {auth} from '../firebaseApp';
import {createContext, useContext, useState, useEffect, use} from "react";
import {
    browserLocalPersistence,
    browserSessionPersistence,
    setPersistence,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(auth.currentUser);
    useEffect(() => {
        setUser(auth.currentUser)
        auth.onAuthStateChanged(async newUser => {
            setUser(newUser);
        })
    }, [user]);

    return <AuthContext.Provider value={{user, setUser}}>{children}</AuthContext.Provider>
}

export const signOut = () => auth.signOut()

export const signInWithEmail = async (email, password, rememberMe) => {
    if (rememberMe) {
        await setPersistence(auth, browserLocalPersistence);
    } else {
        await setPersistence(auth, browserSessionPersistence);
    }
    const user = await signInWithEmailAndPassword(auth, email, password);
    return user.user; ///mapUser(user);
}

export const useAuth = () => {
    const {user} = useContext(AuthContext);
    return user;
}

export const useAuthContext = () => useContext(AuthContext);

export const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    auth.useDeviceLanguage();
    const result = await signInWithPopup(auth, provider);
    return result.user;
}