import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import * as React from "react";
import {useAuth, signOut} from "../hooks/AuthenticationContext";

export default function UserBox() {
    const user = useAuth();
    return <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Avatar
            variant="outlined"
            size="sm"
            src={user.photoURL}
        />
        <Box sx={{minWidth: 0, flex: 1}}>
            <Typography level="title-sm">{user.displayName}</Typography>
            <Typography level="body-xs">{user.email}</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral" onClick={() => signOut()}>
            <LogoutRoundedIcon/>
        </IconButton>
    </Box>
}
